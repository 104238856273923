@import url("https://use.typekit.net/vsb4qqf.css");
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;600&display=swap");

/* cage steel fonts */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700&display=swap");

*,
html {
  scroll-behavior: smooth !important;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  padding: 0;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  overflow-x: hidden;
}

body {
  position: relative;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  font-family: "neue-haas-grotesk-display";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 25px;
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "acumin-pro-extra-condensed";
  margin: 0;
  color: #1d1d1f;
}

h4 {
  font-family: "neue-haas-grotesk-display";
  margin: 0;
  color: #1d1d1f;
}
